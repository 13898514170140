<template>

    <div class="text-gray-100 min-h-screen game" :class="[showInApp.active ? 'max-w-md mx-auto': '', backgroundColor]">

        <div v-if="!wsPing.ok && !showInApp.active" class="fixed w-full text-center bg-red-700 text-white z-50 px-2 py-1">
            ⚠️ Sjekk internettforbindelsen ⚠️
        </div>

        <div v-if="showAgeLimitAlert && gameStarted" class="fixed z-20 w-full min-h-screen" :class="backgroundColor">
            <div class="h-full text-xl text-center mt-20 px-10">              
                <div class="text-4xl mb-4">⚠️</div>
                <div class="mb-3">
                    Din valgte aldersgruppe er for lav til å delta i dette spillet. 
                </div>
                <div>
                    Gå til menyen for å velge en høyere alder eller start et nytt spill med andre kategorier.         
                </div>
            </div>
        </div>           

        <div v-if="!gameQuestion && !showInApp.active">
            <!-- Menu button -->
            <div v-if="!maintenance.active" class="z-20 fixed bg-white m-2 p-2 rounded-full shadow-lg" :class="{'p-3': gameStarted}" @click="openMenu()">                            
                <svg class="h-7 w-7" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                </svg>                      
            </div>
            <div v-if="test.active" class="z-20 fixed top-5 left-20 text-white text-xl font-semibold">
                TEST
            </div>
            
            <div class="z-20 fixed right-0 mt-2" :class="showFullscreenBtn ? 'mr-16' : 'mr-2'">
                <!-- Travel points -->
                <div v-if="travel && gameStarted" class="relative">
                    <div class="flex justify-center">                        
                        <img src="/images/travelPoints.svg" class="mt-1" style="width: 3.7rem" />                        
                    </div>
                    <div class="absolute -ml-0.5 top-1.5 w-full text-3xl font-bold text-center">
                        <span style="color:#564C46">{{ travelPoints }}</span>
                    </div>
                </div>
                <!-- Show previous question button -->
                <div v-else-if="previuosQuestion" class="p-3 bg-white rounded-full" @click="openQuestion(previuosQuestion)">
                    <svg class="h-7 w-7" viewBox="0 0 512 512">
                        <path d="M263.859,47.641c-64.281,0-124.719,30.094-163.813,79.969L40.266,93.047l-0.125,197.844l171.391-98.813l-55.094-31.875 c27.063-30.313,66-48.563,107.422-48.563c79.391,0,144.016,64.594,144.016,144s-64.625,144-144.016,144v64 c114.703,0,208.016-93.313,208.016-208S378.563,47.641,263.859,47.641z"/>
                    </svg> 
                </div>
            </div>
        
            <!-- Toggle fullscreen (in browser only) button -->
            <div v-if="showFullscreenBtn" class="z-20 fixed bg-white m-2 p-2 rounded-full shadow-lg right-0" :class="{'p-3': gameStarted}" @click="toggleFullscreen()">
                <div v-if="isFullscreen">
                    <svg class="h-7 w-7" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/>              
                    </svg>                    
                </div>
                <div v-else>
                    <svg class="h-7 w-7 p-1" viewBox="0 0 300 300">                    
                        <polygon points="42.667,192 0,192 0,298.667 106.667,298.667 106.667,256 42.667,256"/>
                        <polygon points="0,106.667 42.667,106.667 42.667,42.667 106.667,42.667 106.667,0 0,0"/>
                        <polygon points="192,0 192,42.667 256,42.667 256,106.667 298.667,106.667 298.667,0"/>
                        <polygon points="256,256 192,256 192,298.667 298.667,298.667 298.667,192 256,192"/>                              
                    </svg>
                </div>
            </div>
        </div>     

        <div v-if="errorMessage" class="bg-red-300 text-red-900 font-semibold py-4 text-center" v-html="errorMessage"></div>

        <div v-if="!gameStarted" class="flex flex-wrap justify-evenly text-center">  

            <!-- start page -->
            <div v-if="!categories.length" class="mx-4 w-full" _class="mx-6 w-full h-screen overflow-hidden">
                <div class="py-4" :class="backgroundColor">
                    <img src="/images/ainou.png" alt="Ainou" class="w-32 mx-auto">
                </div>

                <div v-if="maintenance.active" v-html="maintenance.msg" class="bg-red-400 text-red-900 font-semibold rounded border px-3 py-2"></div>
                <div v-else>

                    <details v-if="isApple" class="p-3 mt-2 border-2 border-gray-400 rounded-lg text-left leading-snug">                    
                        <summary class="font-semibold text-base tracking-wider text-yellow-400">iPhone: Viktig informasjon!</summary>
                        <div>
                            <div class="mt-1 text-sm">Vi anbefaler at du låser skjermretningen i portrett-modus. Dette vil gi den beste spillopplevelsen.</div>
                            <div class="flex justify-center mt-1 mb-2">
                                <img src="/images/ios-screen-lock.png" alt="Screen Lock" class="rounded-lg w-10 h-10">
                            </div>
                            <div class="text-sm">
                                I tillegg er det lurt å unngå at skjermen går i dvale: Innstillinger > <span class="whitespace-nowrap">Skjerm og lysstyrke</span> > Autolås
                            </div>
                        </div>
                    </details>

                    <div class="my-5">
                        <div v-for="(msg, i) in infoMsg" :key="i" :class="msg.type === 'info' ? 'bg-blue-900 border-blue-100' : 'bg-red-800 border-red-200'" class="text-left p-4 mb-2 border-2 rounded-lg">
                            <div class="whitespace-pre-wrap">{{ msg.message }}</div>
                        </div>
                    </div>

                    <div v-if="travel" class="mb-5 relative">
                        <img src="/images/travel_spinner.png" class="animate-spinner rotate-45 transform m-auto">
                        <img src="/images/travel_spinner_arrow.png" class="-rotate-6 transform absolute m-auto top-0 left-0 right-0 bottom-0">
                    </div>

                    <CategoryButton name="Velg kategorier selv" v-on:selected="getCategories()" />                     
                    eller
                    <CategoryButton name="Spill med tilfeldig valgte kategorier" v-on:selected="selectRandomGameCategories()" /> 
                    
                    <div v-if="(!travel || !connectedCode)" class="mt-6 w-full bg-purple-700 text-white px-3 py-2 rounded-lg border-2 border-gray-400">
                        <div v-if="!travel">
                            <div class="font-semibold tracking-wider text-xl mb-1">✨ Nyhet ✨</div>
                            Vi har lansert en miniutgave av Ainou! 
                            Kategoriene dine fra brettspillet kan kobles 
                            slik at du kan ta med deg Ainou overalt
                            <div class="my-2">
                                <a href="https://ainou.no/mini" target="_blank">
                                    <button class="btn buyBtn">Kjøp her</button>
                                </a>
                            </div>
                        </div> 
                        <div v-else-if="!connectedCode">
                            <div class="font-semibold tracking-wider text-xl mb-1">Brettspill</div>
                            Ainou kommer også i brettspill-utgave.
                            <div class="my-2">
                                <a href="https://ainou.no/brettspill" target="_blank">
                                    <button class="btn buyBtn text-base">Kjøp her</button>
                                </a>
                            </div>                            
                        </div>                   
                    </div>

                </div>                  

            </div>
           
            <!-- select game categories -->
            <div v-else class="mx-2">  
                <div v-if="!atTopOfPage" class="fixed z-10 -ml-2 top-0 w-full h-12 bg-gradient-to-b from-gray-800 to-[rgba(255,255,255,0)]"></div>

                <div class="z-20 ml-16 fixed left-0 mt-2 bg-white flex items-center justify-center rounded-full shadow-lg w-11 h-11" @click="init()">
                    <svg viewBox="0 -256 1792 1792" class="h-6 w-6 ml-1" fill="#333">  
                        <path d="M 742,-37 90,614 Q 53,651 53,704.5 53,758 90,795 l 652,651 q 37,37 90.5,37 53.5,0 90.5,-37 l 75,-75 q 37,-37 37,-90.5 0,-53.5 -37,-90.5 L 512,704 998,219 q 37,-38 37,-91 0,-53 -37,-90 L 923,-37 Q 886,-74 832.5,-74 779,-74 742,-37 z" /> 
                    </svg>
                </div> 
                <div :class="showFullscreenBtn ? 'mr-16' : 'mr-2'" class="text-gray-800 z-20 fixed right-0 mt-2 bg-white pt-1 pb-2 rounded-full shadow-lg text-3xl font-bold w-11 h-11" @click="showCategoryDesc()">?</div> 

                <div class="text-lg py-3 ml-6 mt-1">
                    <span v-if="selectCategoriesComplete">Trykk på <span class="tracking-wider font-semibold">START</span></span>
                    <span v-else class="relative">Velg {{ categoryCount }} kategorier</span>
                </div>

                <div v-if="infoText" class="bg-green-100 text-gray-700 py-2 mt-1 rounded-md relative">
                    <span @click="hideInfoText()" class="absolute right-0 _mr-2 -mt-4 font-bold text-3xl p-2">&times;</span>
                    <div class="px-7" v-html="infoText"></div>
                </div>
                                                               
                <div class="flex flex-wrap justify-between space-y-3 w-full mb-4">
                    <div v-for="(cat, i) in getBoardCategories()" :key="i" class="mt-3 w-5/12 mx-3">                                            
                        <CategoryButton :name="cat.name" :minAgeLabel="getMinAgeLabel(cat.minAgeGroup)" :icon="cat.image" :disabled="!isOrganizer" :selected="cat.selected" v-on:selected="toggleGameCategory(cat)" />            
                    </div>     

                    <div v-if="selectedCategories.length === categoryCount" class="w-full mx-3">
                        <button class="transition bg-green-900 border-2 bg-gradient-to-t from-green-600 text-white font-semibold text-xl py-2 tracking-widest rounded-full w-full mt-3 mb-1" @click="startGame()" ref="startBtn">
                            START
                        </button>
                    </div>
                    
                    <div v-if="getNewCategories().length" class="w-full border-t border-gray-300 mt-5 py-2">
                        Kjøp flere kategorier
                        <div v-if="travel && !connectedCode" class="text-xs">NB! Har du brettspill-utgaven kan du koble kategoriene derfra i menyen oppe til venstre</div>
                    </div>
                    <div v-for="(cat, i) in getNewCategories()" :key="i" class="mt-5 w-5/12 mx-3">
                        <CategoryButton :name="cat.name" :disabled="!isOrganizer" v-on:selected="buyCategory(cat)" :price="cat.price" :isNew="cat.isNew" />            
                    </div>    

                    <div v-if="getUpdatedCategories().length" class="w-full border-t border-gray-300 mt-5 py-2">
                        Oppdater kategorier
                    </div>
                    <div v-for="(cat, i) in getUpdatedCategories()" :key="i" class="mt-5 w-5/12 mx-3">
                        <CategoryButton :name="cat.name" :disabled="!isOrganizer" v-on:selected="buyCategory(cat)" :price="cat.price" />            
                    </div>
                </div>

                <div v-if="!atBottomOfPage" class="fixed -ml-2 bottom-0 w-full h-8 bg-gradient-to-t from-gray-800 to-[rgba(255,255,255,0)]"></div>
            </div>

        </div>



        <!-- Game categories -->
        <div v-else-if="!gameQuestion" :class="backgroundColor"> 

            <div v-if="!showInApp.active" class="text-center flex flex-col min-h-screen text-2xl">
                
                <div v-for="(cat, i) in gameCategories" :key="i" class="tracking-wider" :style="{'color': cat.color.text, 'height': catHeight + 'px'}">
                    <div class="w-full h-full">

                        <div v-if="i === 0" class="inline-flex" @click="getQuestion(i, false)">                            
                            <ColorWheel :width="catHeight" :height="catHeight" :colors="catColors" :travel="travel" symbol="🎲" />                            
                        </div>
                        
                        <div v-else class="flex flex-col justify-center h-full mx-8">
                            <div class="flex w-full h-4/5 rounded-full font-bold" :style="{'background-color': cat.color.bg, 'color': cat.color.text}" @click="getQuestion(i, false)">                                
                                <span class="my-auto w-full">
                                    {{ cat.name }}
                                </span>                                                            
                            </div>                                
                        </div>
                    </div>                    
                </div>
                <!-- [?] button row -->
                <div class="flex" :style="{'height': catHeight + 'px'}">  
                    <div v-for="(cat, i) in categoryCount" :key="i" class="mx-1 mt-1 mb-2 items-center rounded-2xl w-2/3 inline-flex text-6xl font-bold" :style="{'background-color': catColors[i].bg, 'color':  catColors[i].text}">
                        <span class="text-center w-full" @click="getQuestion(i+1, true)">?</span>
                    </div>                    
                </div>
            </div>
        </div>    
    

        <!-- Show question -->
        <div v-else-if="!showAgeLimitAlert" class="min-h-screen text-gray-800 px-2 pb-2 relative" :style="{'background-color': questionCategory.color.light}">
            <div class="pt-4 mb-4 text-center tracking-wide font-semibold text-2xl" :style="{'color': questionCategory.color.text}">{{ questionCategory.name }}</div>

            <div v-if="jokerCard" class="text-center">             
                <img :src="'data:' + jokerCard.mime + ';base64, ' + jokerCard.image" class="mx-auto rounded-xl border border-gray-400" alt="">
                <div v-if="clientId == gameQuestion.clientId" class="bg-white text-lg font-semibold rounded-xl mt-4 p-3" v-html="jokerCard.text"></div>                     
            </div>

            <div v-else>

                <div v-if="showLargePicture" class="fixed top-0 pt-16 w-full max-w-md h-full bg-black z-40 bg-opacity-50 -ml-2" @click="magnifyPicture(false)">
                    <img :src="mediaUrl(gameQuestion.pictureId)" class="w-full border border-gray-600">
                    <div v-if="gameQuestion.mediaAttribution" class="text-xs text-white absolute text-center w-full">
                        {{ gameQuestion.mediaAttribution }}
                    </div>                        
                </div>

                <div v-if="gameQuestion.videoId" class="mb-4 border-2 border-gray-600">
                    <video controls>
                        <source :src="mediaUrl(gameQuestion.videoId)">
                    </video>
                </div>
                <div v-else class="flex flex-wrap justify-center">
                    <div v-if="gameQuestion.pictureId" class="w-full relative inline-flex mb-4"> 
                        <MaskedPicture v-if="gameQuestion.pictureMaskGridX" subText="Hele bildet vises når alle rutene er avdekket" :delay="getMaskedPictureDelay" :sound="sound()" :key="maskedPicKey" :imgUrl="mediaUrl(gameQuestion.pictureId)" :gridX="gameQuestion.pictureMaskGridX" /> 
                        <TimedPicture v-else-if="gameQuestion.pictureTime" :imgUrl="mediaUrl(gameQuestion.pictureId)" :time="getPictureTime" :player="yourQuestion" />
                        <AreaPicture v-else-if="isAreaPicture" :imgUrl="mediaUrl(gameQuestion.pictureId)" :sound="sound()" :area="gameQuestion.answer" :player="yourQuestion" :playerCount="playerCount" :travel="travel" :hideArea="gameQuestion.hideAnswer" v-on:answer="areaPictureAnswer" />
                        <div v-else class="mx-auto">                            
                            <div class="relative" @click="magnifyPicture(true)">
                                <img :src="mediaUrl(gameQuestion.pictureId)" @load="questionImageLoaded" ref="questionImage" alt="Bilde til spørsmål" class="rounded-xl max-h-64 border-2 border-gray-600 bg-white">                        
                                <div v-if="questionImgPortrait" class="absolute top-2 right-2">
                                    <svg class="h-4 w-4" viewBox="0 0 300 300" style="fill: #ffffff">                    
                                        <polygon points="42.667,192 0,192 0,298.667 106.667,298.667 106.667,256 42.667,256"/>
                                        <polygon points="0,106.667 42.667,106.667 42.667,42.667 106.667,42.667 106.667,0 0,0"/>
                                        <polygon points="192,0 192,42.667 256,42.667 256,106.667 298.667,106.667 298.667,0"/>
                                        <polygon points="256,256 192,256 192,298.667 298.667,298.667 298.667,192 256,192"/>                              
                                    </svg>                                    
                                </div>
                            </div>
                        </div>
                        <div v-if="gameQuestion.mediaAttribution" :class="{'bottom-5': gameQuestion.pictureMaskGridX > 0}" class="text-xs text-white absolute z-20 right-1/2 bottom-0 w-max" style="margin-bottom: 3px; left: 50%; transform: translate(-50%, 0%)">
                            {{ gameQuestion.mediaAttribution }}
                        </div>                    
                    </div>
                    <div v-if="gameQuestion.audioId" class="mt-1 mb-4"> 
                        <audio controls>
                            <source :src="mediaUrl(gameQuestion.audioId)">
                        </audio>
                    </div>                    
                </div>

                <div v-if="!yourQuestion || !hasMedia" class="py-4 px-5 mb-2 rounded-xl bg-white text-lg">
                    <div v-if="yourQuestion" class="text-6xl py-6 text-center" v-html="getRandomThinkEmoji()"></div>
                    <div v-else>         
                        <div v-if="gameQuestion.pictureTime" class="font-semibold mb-1">Viktig: Les spørsmålet <span class="underline">etter</span> at tiden over bildet er utløpt</div>
                        <div class="whitespace-pre-wrap">{{ gameQuestion.question }}</div>  <!-- question -->
                        <div v-if="questionHelp" v-html="questionHelp" class="mt-2 italic text-sm leading-tight"></div>
                        <div class="text-right text-xs tracking-wide font-semibold">{{ getAgeAlias(gameQuestion.ageGroup, true) }}</div>                    
                    </div>
                </div>

                <div v-if="gameQuestion.options.length" class="relative z-10"> 
                    
                    <div v-if="travel && gameQuestion.common">                                         
                        <div class="text-center w-1/2 mx-auto font-semibold border rounded bg-gray-400 text-lg py-1 mt-4">
                            {{ formatNumber(gameQuestion.travelCommonValue) }} 
                            {{ travelCommonUnit }}
                        </div>                    
                        <div class="w-full mt-6 mb-2">
                            <input 
                                type="range" 
                                :value="gameQuestion.travelCommonValue" 
                                :min="travelCommonFrom" 
                                :max="travelCommonTo" 
                                :step="getRangeStep(travelCommonFrom, travelCommonTo)" 
                                @input="updateTravelCommonSliderValue($event)" 
                                class="slider"
                            >
                        </div>
                        <div class="text-gray-400 text-sm text-center mb-4">
                            Poeng til den som kommer nærmest riktig svar
                        </div>
                    </div>

                    <div v-else>
                        <div class="ml-1 pt-2 mb-1" :style="{'color': questionCategory.color.text}">
                            <span v-if="gameQuestion.common">Velg et alternativ</span>
                            <span v-else>Alternativer</span>
                        </div>
                        <div>
                            <div v-for="(opt,i) in gameQuestion.options" :key="i" class="rounded-lg flex items-center bg-white px-3 py-2 mb-1 border-4 border-white" :class="{'border-4 border-green-500 bg-green-100': i === gameQuestion.commonOptionIdx}" @click="gameQuestion.common ? gameQuestion.commonOptionIdx=i : ''">
                                <span class="mr-2 font-semibold">{{ alphabet[i] }})</span><span v-html="formatOption(opt)"></span>
                            </div>     
                            <div v-if="!gameQuestion.common && !yourQuestion" class="bg-gray-300 py-2 rounded-xl text-center text-lg font-semibold" @click="toggleAnswer()">
                                <div v-if="gameQuestion.hideAnswer">
                                    Trykk her for å se svaret
                                </div>
                                <div v-else>
                                    {{ alphabet[gameQuestion.answer*1] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showQuestionTimer" class="z-0 flex justify-center mt-2">
                    <Timer ref="timer" :start="getQuestionTime" v-on:timer-end="closeQuestion(true)" :sound="sound()" :key="timerKey" :colors="catColors" :showColorWheel="gameQuestion.common" />                            
                </div> 

                <div v-if="!yourQuestion && !gameQuestion.options.length"> 
                    <div class="ml-1" :style="{'color': questionCategory.color.text}">Svar</div>
                    <div class="rounded-xl py-2 px-5 text-lg bg-gray-300" @click="toggleAnswer()">
                        <div v-if="gameQuestion.hideAnswer" class="text-center font-semibold">
                            Trykk her for å se svaret
                        </div>                        
                        <div v-else>
                            <span v-if="isAreaPicture" class="text-center font-semibold">Skjul område</span>
                            <span v-else>{{ gameQuestion.answer  }}</span>                        
                        </div>
                    </div>
                </div>  

                <div v-if="travel && !yourQuestion && !gameQuestion.common && !isAreaPicture" class="mt-8">
                    <div class="flex justify-center gap-x-6">
                        <div class="pointButton bg-green-700" @click="travelAnswer(1, gameQuestion.clientId)">👍🏻</div>
                        <div class="pointButton bg-red-700" @click="travelAnswer(0, gameQuestion.clientId)">👎🏻</div>
                    </div>
                </div>                 

                <div v-if="gameQuestion.card">
                    <div class="fixed bottom-20 right-0 mr-4 text-center bg-white rounded-full shadow-lg text-5xl pt-1 w-14 h-14 z-20" @click="showCard(gameQuestion)">?</div> 
                </div>
            </div>

            <div v-if="canCloseQuestion" class="fixed bottom-0 right-0 mr-4 mb-4 bg-white p-2 rounded-full shadow-lg z-20" @click="closeQuestion()">
                <svg class="h-10 w-10" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                </svg>                
            </div>            

        </div>                    

    </div>

    <Menu ref="menu" 
        :playerCount="playerCount" 
        :isOrganizer="isOrganizer" 
        :ageAlias="getAgeAlias(playerAgeGroup, false)" 
        :questionTime="questionTimeLabel(questionTime)"
        :gameStarted="gameStarted" 
        :boardCode="code"
        :connectedCode="connectedCode"
        :travel="travel"
        v-on:set-age="openAgeSelect()" 
        v-on:end-game="stopGame(true)" 
        v-on:new-organizer="newOrganizer()" 
        v-on:new-board="newBoard"
        v-on:set-question-time="openQuestionTimeSelect()"
        v-on:ainou-connected="ainouTravelConnected"
        v-on:ainou-disconnected="ainouTravelDisconnected"
    />
    <Alert ref="alert" :textLarge="true" />
    <Alert ref="dialog" :closeX="true" />
    <ScanQR ref="scanQR" />
    <BuyCategory ref="buyDlg" />
    <QuestionAnimation ref="questionAnimation" />
    <LotteryCard ref="lotteryCard" :sound="sound()" v-on:travel-lottery-result="travelLotteryResult" />
    <GameStartDialog 
        ref="gameStartDlg" 
        :travel="travel" 
        :questionTime="questionTime" 
        v-on:set-question-time="setQuestionTime"
        v-on:set-travel-game-time="setTravelGameTime"
    />
    <TravelGameFinishDlg ref="travelGameFinishDlg" :sound="sound()" />
    <Sounds ref="sounds" />

</template>

<script>
import axios from 'axios';
//import Copyright from '@/components/Copyright.vue';
import CategoryButton from '@/components/CategoryButton.vue';
import Menu from '@/components/Menu.vue';
import Alert from '@/components/Alert.vue';
import Timer from '@/components/Timer.vue';
import ColorWheel from '@/components/ColorWheel.vue';
import MaskedPicture from '@/components/MaskedPicture.vue';
import TimedPicture from '@/components/TimedPicture.vue';
import AreaPicture from '@/components/AreaPicture.vue';
import ScanQR from '@/components/ScanQR.vue';
import BuyCategory from '@/components/BuyCategory.vue';
import QuestionAnimation from '@/components/QuestionAnimation.vue';
import GameStartDialog from '@/components/GameStartDialog.vue';
import TravelGameFinishDlg from '@/components/TravelGameFinishDlg.vue';
import LotteryCard from '@/components/LotteryCard.vue';
import { catVersion, questionTimeLabel, formatNumber, getRangeStep } from '@/utils.js';
import Sounds from '@/components/Sounds.vue';

export default {
    components: {
        CategoryButton,
        Menu,
        Alert,
        Timer,
        ColorWheel,
        ScanQR,
        BuyCategory,
        //Copyright,
        MaskedPicture,    
        TimedPicture,
        AreaPicture,
        QuestionAnimation,   
        LotteryCard, 
        GameStartDialog,
        TravelGameFinishDlg,
        Sounds,
    },    
    data() {
        return {
            maintenance: {
                active: false, // activate this if server-side is not available; will prevent QR-scan/start game
                msg: '⚠️<b>Vedlikehold pågår</b>⚠️<br>Grunnet vedlikehold på vår server er Ainou dessverre utilgjengelig i dag fra kl 21 til midnatt.', 
            },                        
            categoryCount: 6,           
            ageGroups: [  // hide => hide age group in question
                { id: 1, alias: 'Barn (10-13)', hide: false, enabled: true },
                { id: 2, alias: 'Ungdom (13-16)', hide: false, enabled: true },
                { id: 3, alias: 'Voksen (16+)', hide: true, enabled: true },
            ],
            ageGroupDesc: 'Barn og ungdom velger den aldersgruppen de føler seg mest hjemme i. Aldersgruppe kan endres underveis i spillet.',            
            questionTime: 0,
            cardExtraTime: 10,  // extra seconds if card is showing before question
            isOrganizer: false,
            uuid: null,
            code: null,
            connectedCode: null,
            playerCount: 0,            
            normalCatColors: [
                { bg: '#CF342C', text: '#fff', light: '#EB3439', dark: '#92241F' }, // red  
                { bg: '#F68936', text: '#fff', light: '#F68936', dark: '#B95409' }, // orange
                { bg: '#F6D65B', text: '#fff', light: '#F6D65B', dark: '#BF9B0B' }, // yellow
                { bg: '#4D9F45', text: '#fff', light: '#4D9F45', dark: '#2D5C27' }, // green
                { bg: '#24659C', text: '#fff', light: '#24659C', dark: '#0E263A' }, // blue
                { bg: '#A9278E', text: '#fff', light: '#A9278E', dark: '#661756' }, // purple                                                                
            ],      
            travelCatColors: [
                { bg: '#E03E52', text: '#fff', light: '#E03E52', dark: '#92241F' }, // red  
                { bg: '#FF7F32', text: '#fff', light: '#FF7F32', dark: '#B95409' }, // orange
                { bg: '#FDD757', text: '#fff', light: '#FDD757', dark: '#BF9B0B' }, // yellow
                { bg: '#4C8C2B', text: '#fff', light: '#4C8C2B', dark: '#2D5C27' }, // green
                { bg: '#00558C', text: '#fff', light: '#00558C', dark: '#0E263A' }, // blue
                { bg: '#93328E', text: '#fff', light: '#93328E', dark: '#661756' }, // purple                                                                
            ],            
            commonRoundCategory: {
                id: 0,
                color: { bg: '#BDBDC0', text: '#fff', light: '#BDBDC0' },           
                time: 15,  // standard answer time in seconds
                extraTimeWordCount: 10, // extra time given if question word count exeeds this value
                extraTimePrWord: 0.4, // per word exeeding word count limit
            },
            travelCommonCategory: {
                name: 'Fellesrunde',
                id: process.env.VUE_APP_TRAVEL_COMMON_ID,  // special catageory for travel common round
                fromDate: { day: 1, month: 1, year: 2021 },                        
                versionDate: { day: 1, month: 1, year: 2100 },
                time: 15,  // standard answer time in seconds
                extraTimeWordCount: 10, // extra time given if question word count exeeds this value
                extraTimePrWord: 0.4, // per word exeeding word count limit                
                color: { bg: '#12355A', text: '#fff', light: '#12355A' },
            },         
            newsCategoryID: 9,
            noCommonRoundCat: [23],  // category-ids; will not be selected for common-rounds
            thinkEmojis: ['🙄', '🧐', '🤔', '🤨'],
            categories: [],
            questionCategory: null,
            gameCategories: [],
            gameQuestion: null,
            previuosQuestion: null,
            questionImgPortrait: false,            
            isFullscreen: false,
            catHeight: 2,
            alphabet: ['A', 'B', 'C', 'D', 'E', 'F'],
            infoMsg: [],
            showLargePicture: false,
            errorMessage: '',
            atTopOfPage: true,
            atBottomOfPage: false,
            timerKey: 0,
            maskedPicKey: 0,
            maskAudio: null,
            pointPlusAudio: null,
            pointMinusAudio: null,
            winnerAudio: null,
            wakeLock: '',
            joker: {},
            robber: {},
            simulator: {
                active: false,
                questionTime: 2,
                delays: [3,4,5]
            },
            test: {
                active: false,
                question: {  // scrambled question id by category id
                    1: 462428980,   // Vitenskap
                    2: 112877037,   // Natur og friluftsliv                    
                    3: 399634196,   // Tro og overtro
                    4: 446986241,   // Sport og spill
                    5: 746114655,   // Mat og drikke
                    6: 527100782,   // Populærkultur
                    7: 789797983,   // Historie
                    8: 534438216,   // Verden rundt
                    9: 842183849,   // Aktuelt/nyheter
                    10: 625217632,  // Hovedsteder
                    11: 589383652,  // Opp av hatten
                    12: 550904783,  // Kropp og helse
                    13: 558156898,  // Norge
                    14: 207069213,  // Land
                    18: 743384447,  // Teknologi
                    19: 0, // Kunst
                    20: 0, // Litteratur
                    21: 190858603, // Musikk
                    23: 712413650, // På engelsk
                    24: 0,  // Hus og hjem
                    25: 0,  // Trafikk
                    26: 0,  // Harry Potter
                    27: 1015808014, //772904821,  // Melodi Grand Prix
                    28: 0,  // Ringenes Herre
                    29: 825546644,  // God jul
                }
            },
            questionHelp: '',
            helpText: [
                {
                    trigger: 'anagramoppgave',
                    text: 'Et anagram er et ord, navn eller uttrykk som er satt sammen ved å stokke om bokstavene i et annet ord, navn eller uttrykk.',
                }
            ],     
            showInApp: {
                active: false,
                asPlayer: false,  
                questionId: 0,
            },  
            infoText: '',
            wsConn: null,
            wsPing: {
                ok: true,
                pong: false,
                interval: null,
            },             
            travel: false,         
            travelGameTime: 30,  
            travelPoints: 0,
        }
    },
    computed: {
        clientId() {
            return this.$store.getters.clientId;
        },
        selectedCategories() {
            const selCat = [];
            for (let i = 0; i < this.categories.length; i++) {
                const boardCat = this.categories[i];
                if (boardCat.selected) {
                    selCat.push(boardCat);
                }
            }
            return selCat;
        },
        selectCategoriesComplete() {
            return this.selectedCategories.length === this.categoryCount;
        },
        showQuestionTimer() {
            return this.gameQuestion.common || (this.questionTime > 0 && !this.gameQuestion.pictureMaskGridX); 
        },        
        maxPlayerCount() {
            return this.travel ? 10 : 6;
        },
        getQuestionTime() {
            if (this.gameQuestion.common) {                
                let sec = this.commonRoundCategory.time;
                let wordCount = this.gameQuestion.question.split(' ').length;
                if (wordCount > this.commonRoundCategory.extraTimeWordCount) {
                    sec += Math.round((wordCount - this.commonRoundCategory.extraTimeWordCount)*this.commonRoundCategory.extraTimePrWord);
                }
                return sec;
            }
            else if (this.gameQuestion.card) {
                return this.questionTime + this.cardExtraTime;
            }
            else {
                return this.questionTime;
            }
        },
        getPictureTime() {
            let time = this.gameQuestion.pictureTime;
            if (time && this.gameQuestion.card) {
                time += this.cardExtraTime;
            }
            return time;
        },
        getMaskedPictureDelay: function() {
            return this.gameQuestion.card ? this.cardExtraTime : 0;
        },
        gameStarted() {
            return this.gameCategories.length > 0;
        },       
        gameId() {
            return this.$store.getters.game.id;
        },
        score() {
            return this.$store.getters.game.score;
        },
        commonRoundQuestions() {
            return this.$store.getters.game.commonRound;
        },
        playerAgeGroup() {
            return this.$store.getters.ageGroup;
        },
        yourQuestion() {
            if (this.showInApp.active) {
                return this.showInApp.asPlayer;
            }
            return this.gameQuestion.clientId == this.clientId && !this.gameQuestion.common && this.playerCount > 1; 
        },
        hasMedia() {
            return this.gameQuestion.pictureId || this.gameQuestion.audioId || this.gameQuestion.videoId;
        },
        showFullscreenBtn() {
            const isApp = window.matchMedia('(display-mode: standalone)').matches;
            return !isApp && !this.isApple;
        },           
        isApple() {
            const userAgent = navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        },    
        isSim() {
            return this.simulator.active;
        },
        showAgeLimitAlert() {
            return this.playerAgeGroup < this.getMinAgeGroup();   
        },
        backgroundColor() {
            return 'bg-blue'; 
        },
        jokerCard() {
            return this.gameQuestion?.joker ? this.joker : this.gameQuestion?.robber ? this.robber : null;
        },
        travelCommonFrom() {
            return this.gameQuestion.options[this.playerAgeGroup-1][0];
        },
        travelCommonTo() {
            return this.gameQuestion.options[this.playerAgeGroup-1][1];
        },
        travelCommonUnit() {
            return this.gameQuestion.options[this.playerAgeGroup-1][2];
        },
        canCloseQuestion() {            
            return !this.gameQuestion.common && !this.showInApp.active && (!this.travel || this.jokerCard || this.yourQuestion || this.isAreaPicture);
        },     
        catColors() {
            return this.travel ? this.travelCatColors : this.normalCatColors;
        },
        isAreaPicture() {
            return this.gameQuestion?.answer.startsWith('<area');
        },        
    },  
    created() {
        this.forceSWupdate();        
        this.init();
        this.calcCategoryHeight();
        if (this.isApple) {
            window.addEventListener('orientationchange', this.onOrientationChange);        
        }
        else {
            window.addEventListener('resize', this.calcCategoryHeight);        
            window.addEventListener('visibilitychange', () => {
                if (!document.hidden) {
                    this.requestWakeLock();  // request new wakeLock after app is visible (after run in background on mobile phone)
                }
            });
        }     
        document.dispatchEvent(new Event('render-trigger'));   // pre-render     
    },    
    beforeMount () {
        window.addEventListener('scroll', this.handleScroll);
        this.requestWakeLock();        
    },      
    mounted() {     
        if (!this.maintenance.active) {
            this.startApp();            
        }
    },
    methods: {     
        questionTimeLabel,
        formatNumber,    
        getRangeStep,
        startApp: function(forceQRscan) {
            const instUuid = this.getQueryParam('uuid');
            this.simulator.active = this.getQueryParam('sim') === 'true';
            this.test.active = this.getQueryParam('test') === 'true';
            this.$refs.scanQR.getUUID(instUuid, this.isSim, forceQRscan).then(board => {             
                this.uuid = board.uuid;
                this.code = board.code;        
                this.connectedCode = board.connectedCode;
                this.travel = board.isTravel;                                  
                
                const showQuestionId = this.getQueryParam('qid');
                if (showQuestionId) {  // show standalone question              
                    this.startShowInApp(showQuestionId);
                }

                this.getJoker();  
                this.getRobber();                                              
                if (this.isSim) {
                    this.setAgeGroup(3);            
                }
                else if (!this.playerAgeGroup) {
                    this.openAgeSelect();
                }         
                this.openWebsocket();    
                
                window.scrollTo(0, 0);
            });
        },
        startShowInApp: function(questionId) {
            this.showInApp.active = true;
            this.showInApp.asPlayer = this.getQueryParam('player') == 'true';
            this.showInApp.questionId = questionId;
            this.setAgeGroup(3);
            this.openWebsocket();  
        },
        tryStartSimulator: function() {            
            if (this.simulator.active) {                
                this.questionTime = this.simulator.questionTime;
                if (this.isOrganizer) {                    
                    this.getCategories();                    
                    const waitInt = setInterval(() => {
                        if (this.categories.length > 0 && this.playerCount > 1) {
                            clearInterval(waitInt);  
                            this.setGameCategories(this.categories); // will only use the first 6..
                            const delayIdx = Math.floor(Math.random()*this.simulator.delays.length); 
                            setInterval(() => {                                                            
                                const catIdx = Math.floor(Math.random()*this.categoryCount) + 1;   
                                this.getQuestion(catIdx, false);
                            }, this.simulator.delays[delayIdx]*1000);
                        }
                    }, 1000);
                }
            }
        },
        getQueryParam: function(name) {            
            const params = window.location.search.split('?')[1].split('&');
            for (let i = 0; i < params.length; i++) {
                const p = params[i].split('=');
                if (p[0] === name) {
                    return p[1];
                }
            }
            return '';
        },
        openWebsocket: function() {            
            if (this.playerAgeGroup) {            
                this.wsConn = new WebSocket(process.env.VUE_APP_WS_ENDPOINT + '?clientId=' + this.clientId + '&uuid=' + this.uuid + '&ageGroup=' + this.playerAgeGroup);  
                this.wsConn.onopen = () => {
                    console.log('Websocket open');
                    const wsReq = {
                        type: 'JOIN_GAME',
                        gameId: this.gameId,
                    };
                    console.log('joining game...');
                    this.wsSend(wsReq);
                    this.startConnectionCheck(4);
                }
                this.wsConn.onclose = (e) => { 
                    console.log('Websocket closed (' + e.code + ')');
                    if (e.code !== 1000) {  // 1000 = server closed session from same client (do not try to reconnect)                              
                        setTimeout(this.openWebsocket(), 500);  // try reconnect
                    }
                    clearInterval(this.wsPing.interval);
                    if (e.code < 4000) {  // unexpected disconnect
                        this.wsPing.ok = false; 
                    }
                }            
                this.wsConn.onmessage = this.onWebsocketMessage; 
            }
        },
        wsClose: function(closeCode) {
            if (this.wsConn) {
                this.wsConn.close(closeCode); 
            }
        },
        startConnectionCheck: function(intervalSec) {
            const pingRequest = {
                type: 'PING',
            };            
            this.wsPing.interval = setInterval(() => {
                this.wsPing.pong = false;
                this.wsSend(pingRequest);
                setTimeout(() => {  // wait for 'pong'
                    this.wsPing.ok = this.wsPing.pong;  // ws connection ok if 'pong' received
                }, 1000);  // check for pong response after 1 sec
            }, intervalSec*1000);            
        },
        wsSend: function(sendObj) {
            sendObj['uuid'] = this.uuid; 
            if (this.wsConn.readyState === this.wsConn.OPEN) {  
                this.wsConn.send(JSON.stringify(sendObj));
            }
        },
        onWebsocketMessage: function(e) {
            const msg = JSON.parse(e.data);                  
            //console.log(msg);

            if (msg.pong) {            
                this.wsPing.pong = msg.pong;
                return;
            }
            if (msg.success === false) {
                console.log(msg);
                this.showAlertMsg('<div class="text-4xl">😬</div>', msg.error || 'En feil har oppstått');
                return;
            }

            if (msg.organizer) {
                this.isOrganizer = msg.organizer == this.clientId;
            }
            if (msg.playerCount) {
                this.playerCount = msg.playerCount;                      
            }

            if (msg.type === 'INIT') {
                if (!this.isOrganizer) {
                    this.init();
                }
            }
            else if (msg.type === 'JOIN_GAME') {
                console.log('player joined: ' + msg.clientId + ' (players: ' + this.playerCount + ')');                 
                if (this.showInApp.active) {
                    this.gameCategories[0] = {  // dummy category
                        id: 1,
                        name: 'AINOU',
                        color: this.catColors[4],  // blue
                        fromDate: { day: 1, month: 1, year: 2021},
                        versionDate: { day: 1, month: 1, year: 2100},                    
                    }
                    this.getQuestion(0, false, this.showInApp.questionId);                    
                }
                else if (msg.clientId != this.clientId) {  // when a new player joins, other players broadcast timer settings and gameId
                    this.wsSend({ type: 'SET_QUESTION_TIMER', questionTime: this.questionTime });
                    this.wsSend({ type: 'SET_GAME_ID', gameId: this.gameId }); 
                }
                else {                    
                    if (this.playerCount > this.maxPlayerCount) {  // no room for this player...
                        this.wsClose(4001);
                        this.startApp(true);  // scan QR again
                    }
                    else if (this.travel) {  // update this players travel game points
                        this.travelPoints = msg.travelPoints;
                    }
                }
                /*else {
                    this.tryStartSimulator();
                }*/
            }      
            else if (msg.type === 'LEAVE') {
                console.log('player left: ' + msg.clientId + ' (players: ' + this.playerCount + ')');   
            }      
            else if (msg.type === 'SET_GAME_ID') {                
                if (msg.gameId && (msg.gameId !== this.gameId)) {
                    this.travelPoints = 0;
                    this.$store.commit('clearGame');  // clear score for this game (keep score if joining same game)
                    this.$store.commit('setGameId', msg.gameId);                                        
                    console.log('new game id: ' + msg.gameId);
                }                
            }
            else if (msg.type === 'GET_CATEGORIES') {        
                if (!this.isOrganizer) { 
                    this.getCategories();
                }
            }            
            else if (msg.type === 'SET_CATEGORIES') {          
                if (!this.isOrganizer) {  
                    this.setGameCategories(msg.gameCategories);  
                }
            }
            else if (msg.type === 'SET_QUESTION_TIMER') {            
                this.questionTime = msg.questionTime;    
                this.timerKey++;  // restart timer               
            }
            else if (msg.type === 'QUESTION') {                     
                this.questionCategory = this.travel && msg.common ? this.travelCommonCategory : this.getGameCategory(msg.catId);
                this.gameQuestion = null;  // important, so if previous question is showing, new question animation will have category-buttons as background                
                if (this.questionCategory) {                        
                    this.openQuestion(msg);
                    if (this.showInApp.active) {
                        this.wsClose(1000);  // close, no reconnect
                    }
                }
            }
            else if (msg.type === 'STOP_GAME_FROM_SERVER') {  // travel game auto-stop from server
                this.stopGame(false);  
                this.showScoreDlg(msg);
            }            
            else if (msg.type === 'STOP_GAME') {  // manual stop game from organizer
                if (!this.isOrganizer) {
                    this.stopGame(true);
                }
                this.showScoreDlg(msg);
            }
            else if (msg.type === 'TRAVEL_POINT') {                 
                let lotteryOpen = this.$refs.lotteryCard.isOpen(); 
                if (msg.travelPointsClientId == this.clientId) {                    
                    this.playPointSound(msg.travelPoints);
                    this.travelPoints = msg.travelPoints; // update total score                                  
                }
                else if (lotteryOpen) {
                    this.$refs.lotteryCard.close();
                }
                
                if (!this.jokerCard && !lotteryOpen && !this.isAreaPicture) {
                    this.closeQuestion();
                }
            }
        },
        showCard: function(gameQuestion) {
            if (this.test.active) {
                gameQuestion.card = 'Lotteri';  // test lottery
            }
            const cardText = gameQuestion.card;
            if (cardText.startsWith('Lotteri')) {
                const player = gameQuestion.clientId == this.clientId;                
                this.$refs.lotteryCard.open(player, this.travel);
                gameQuestion.card = ''; // prevent re-open lottery card
            }
            else {              
                const cardHeading = '<span class="text-6xl text-white font-semibold border-2 border-gray-400 px-4 rounded-lg" style="background-color: ' + this.questionCategory.color.bg + '">?</span>';  
                this.$refs.alert.open(cardHeading, cardText.replace('\n', '<br>'), 'OK').then(() => {    
                    //this.timerKey++;  // restart timer (will cause unsync amongst playes..)
                });
            }
        },
        newBoard: function(board) {            
            console.log(board);
            this.uuid = board.uuid;
            this.code = board.code;
            this.connectedCode = board.connectedCode;
            this.travel = board.isTravel;
            this.categories = [];
            this.wsClose(4002);
        },
        handleScroll() {
            this.atBottomOfPage = (document.body.scrollHeight - window.innerHeight - document.body.scrollTop) - window.scrollY < 10;
            this.atTopOfPage = window.scrollY < 10;
        },        
        startGame: function() {
            this.$refs.startBtn?.classList.add('animate-ping');            
            setTimeout(() => {
                this.openQuestionTimeSelect(true);
                const newGameId = Math.random().toString(36).substring(2, 7);         
                this.wsSend({ type: 'SET_GAME_ID', gameId: newGameId });  
                this.setGameCategories(this.selectedCategories);  
            }, 300);  // wait for animation to finish..
        },
        getJoker: function() {
            axios.get('/joker').then(res => {                
                this.joker = res.data;
            });
        },
        getRobber: function() {
            axios.get('/robber').then(res => {                
                this.robber = res.data;
            });
        },        
        stopGame: function(sendStop) {                   
            this.gameQuestion = null; 
            this.previuosQuestion = null;
            this.gameCategories = [];
            this.getCategories();
            if (this.isOrganizer && sendStop) {            
                this.wsSend({ type: 'STOP_GAME', gameId: this.gameId });
            }                        
        },
        areaPictureAnswer: function(correct) {            
            if (this.travel) {
                const points = correct ? 1 : 0;
                this.travelAnswer(points, this.gameQuestion.clientId);
            }
        },
        showScoreDlg: function(msg) {      
            if (this.travel) {
                this.$refs.travelGameFinishDlg.open(msg.travelScoreData); 
            }
            else {            
                const plural = this.score > 1 ? 'er' : '';
                const emoji = '<div class="text-4xl -mt-4 pb-6">' + (this.score > 0 ? '🎉' : '😕') + '</div>'; 
                let msg = this.score > 0 ? emoji + 'Forsyn deg med ' + this.score + ' ekstra gullstein' + plural + '!' : emoji + 'Beklager, det ble ingen ekstra poeng denne gangen';
                msg += '<div class="mt-4 flex flex-wrap justify-center">';
                for (let i = 0; i < this.score; i++) {                    
                    msg += '<img src="/images/travelPoints.svg" style="width: 1.8rem; margin-right: 2px; margin-bottom: 3px;" />';
                }
                msg += '</div>';

                msg += '<div class="mt-6 text-left text-base">';
                for (let i = 0; i < this.commonRoundQuestions.length; i++) {
                    const crq = this.commonRoundQuestions[i];                
                    msg += '<div>' + crq.question + '</div>';
                    msg += '<div class="mt-1 pb-2 mb-2 border-b border-gray-400"><span class="mr-1">' + (crq.correct ? '✔️' : '❌') + '</span><span>' + crq.answer + '</span></div>';
                }
                msg += '</div>';

                this.$refs.alert.open('Poeng fellesrunde: ' + this.score, msg, 'Lukk');
            }
        },
        getMinAgeGroup: function() {
            let min = 1;
            for (let i = 1; i < this.gameCategories.length; i++) {  // skip first game category; common round
                const ag = this.gameCategories[i].minAgeGroup;
                if (ag > min) {
                    min = ag;
                }
             }
            return min;
        },
        openAgeSelect: function() {
            const minAgeGroup = this.getMinAgeGroup();
            let ageSel = '';
            let defaultGroup = this.playerAgeGroup || 3;
            this.ageGroups.forEach((group) => {
                const selected = defaultGroup == group.id ? 'checked' : '';
                const disabled = group.enabled && group.id >= minAgeGroup ? '' : 'disabled="disabled"';
                ageSel += '<div class="mb-2 text-left ml-12"><input type="radio" name="age" value="' + group.id + '" id="group' + group.id + '" ' +  selected + ' ' + disabled + '><label for="group' + group.id + '" class="ml-2 text-lg">' + group.alias + '</label></div>';
            });
            ageSel += '<div class="mt-6 text-base text-left ml-2">' + this.ageGroupDesc + '</div>';
            this.$refs.alert.open('Velg din aldersgruppe', ageSel, 'OK').then(() => {
                document.getElementsByName('age').forEach((a) => {
                    if (a.checked) {                        
                        this.setAgeGroup(a.value*1);              
                        if (this.wsConn) {
                            this.wsClose(4000);  // force new ws login after age change
                        }
                        else {
                            this.openWebsocket();  // first time age group set
                        }
                    }
                });                
            });
        },        
        setAgeGroup: function(group) {
            this.$store.commit('setAgeGroup', group);      
        },
        openQuestionTimeSelect: function(init) {
            this.$refs.gameStartDlg.open(init, this.travelGameTime).then(() => {
                if (this.travel && init) {
                    this.startTravelGame();
                }
            })
        },
        setQuestionTime: function(time) {            
            this.wsSend({ type: 'SET_QUESTION_TIMER', questionTime: time });  
        },
        setTravelGameTime: function(time) {
            this.travelGameTime = time;
        },      
        openQuestion: function(question) {    
            if (this.showAgeLimitAlert) {
                return;
            }
            const questionDelay = question.card ? 2000 : 0;  // delay for the players to read card before question showing
            if (question.card) {  // show '?' card
                this.showCard(question);
            }            
            this.$refs.questionAnimation.open(this.questionCategory, questionDelay).then(() => {            
                this.magnifyPicture(false);

                this.gameQuestion = question;  // show broadcasted question  
                if (!this.jokerCard) {
                    this.tryAddHelp(question.question);
                } 
                                                    
                this.gameQuestion.hideAnswer = true;
                this.gameQuestion.commonOptionIdx = -1;      
                this.timerKey++;  // force re-render timer component, to reset seconds                                  
                this.maskedPicKey++;

                if (this.travel) {
                    if (this.yourQuestion && this.jokerCard) { 
                        const points = this.gameQuestion.joker ? 1 : -1;
                        this.travelAnswer(points, this.clientId);  // auto-point on joker/robber
                    }
                    if (this.gameQuestion.common) {
                        let commonInitValue = Math.round(this.travelCommonFrom + (this.travelCommonTo-this.travelCommonFrom)/2); // init the range-slider value (in the middle)                        
                        this.gameQuestion.travelCommonValue = commonInitValue;
                    }
                }

            });
        },
        tryAddHelp: function(question) {
            this.questionHelp = '';
            const q = question.toLowerCase();                                    
            for (let i = 0; i < this.helpText.length; i++) {
                if (q.startsWith(this.helpText[i].trigger.toLowerCase())) {
                    const cl = this.isApple ? '' : 'bg-blue-500 text-white rounded-full px-1';
                    this.questionHelp = '<span class="not-italic mr-1 ' + cl + '">&#8505;</span>' + this.helpText[i].text;
                    break;
                }
            }
        },
        closeQuestion: function(countdownEnded) {

            if (this.travel && countdownEnded && !this.yourQuestion && !this.gameQuestion.common) {  // not auto-close, can give points                
                return;
            }

            if (this.gameQuestion?.common) {               
                if (this.travel) {  // send answer to server
                    this.wsSend({ 
                        type: 'TRAVEL_COMMON_ANSWER', 
                        gameId: this.gameId,
                        questionId: this.gameQuestion.id, 
                        travelCommonAnswer: this.gameQuestion.travelCommonValue
                    });
                }
                else {
                    const commonRoundQuestion = {
                        question: this.gameQuestion.question,
                        answer: this.gameQuestion.options[this.gameQuestion.answer],
                        correct: this.gameQuestion.commonOptionIdx == this.gameQuestion.answer
                    }                   
                    this.$store.commit('addCommonRoundQuestion', commonRoundQuestion);                                    
                }
            }
            this.previuosQuestion = this.gameQuestion?.common || this.jokerCard || this.gameQuestion?.pictureTime ? null : this.gameQuestion;
            if (this.gameQuestion) {
                this.$refs.questionAnimation.close();
                this.gameQuestion = null;            
            }
            this.calcCategoryHeight();  // in case of zoom/orientation change has happend (on iOS)                                   
        },     
        toggleAnswer: function() {
            this.gameQuestion.hideAnswer = !this.gameQuestion.hideAnswer;
        },
        getInfoMsg: function() {
            axios.get('/messages').then((res) => {
                this.infoMsg = res.data;
            });
        },
        init: function() {
            this.categories = [];
            this.getInfoMsg();            
            if (this.isOrganizer) {
                this.wsSend({ type: 'INIT' });
            }
            window.scrollTo(0, 0);
        },
        getBoardCategories() {
            let cats = [];
            this.categories.forEach(cat => {                
                const isFree = cat.price === 0 && !cat.isTravel;
                if (cat.boardCategory || isFree) {                    
                   cats.push(cat);
                }
            });
            return cats;
        },
        getNewCategories() {
            let cats = [];
            this.categories.forEach(cat => {                                  
                if (!cat.boardCategory && cat.price > 0 && !cat.isTravel) {
                    cats.push(cat);
                }
            });
            return cats;
        },   
        getUpdatedCategories: function() {
            let cats = [];
            this.categories.forEach(cat => {                
                if (cat.newVersionQuestionDiff && cat.price > 0) {
                   cats.push(cat);
                }
            });
            return cats;
        },
        getCategories: function() {
            if (this.isOrganizer) {
                this.wsSend({ type: 'GET_CATEGORIES' }); 
            }
            axios.get('/board/categories?uuid=' + this.uuid).then(res => {                      
                this.categories = res.data;     
                if (this.getBoardCategories().length === this.categoryCount && this.isOrganizer) {  // auto-select all board categories
                    this.getBoardCategories().forEach(cat => {                        
                        cat.selected = true;
                    });
                }
            }).catch(() => {                
                this.errorMessage = '🥵 Det har oppstått en feil!';
            });
        },     
        selectRandomGameCategories: function() {
            axios.get('/board/categories?uuid=' + this.uuid).then(res => { 
                this.categories = res.data;   
                const boardCategories = this.categories.filter(cat => cat.boardCategory);
                if (boardCategories.length >= this.categoryCount) {
                    do {
                        const rand = Math.floor(Math.random() * this.categories.length);
                        const cat = this.categories[rand];
                        if (cat.boardCategory) {
                            cat.selected = true;
                        }
                    } while (this.selectedCategories.length < this.categoryCount);                                
                    this.startGame();
                }
            });
        },
        getGameCategory: function(id) {
            for (let i = 0; i < this.gameCategories.length; i++) {      
                if (this.gameCategories[i].id === id) {
                    const gc = this.gameCategories[i];
                    gc.idx = i;
                    return gc;
                }
            }
            return null;
        },
        getQuestion: function(catIdx, card, questionId) {
            this.initAudio();  // need to do this on user interaction (iOS)
            let gameCategory = this.gameCategories[catIdx];
            if (!questionId && this.test.active) {
                questionId = this.test.question[gameCategory.id];  // hardcoded test-question for this category                
            }            
            let common = false ;                    

            if (gameCategory.id === this.commonRoundCategory.id) {  // common round                                        
                if (this.travel) {
                    gameCategory = this.travelCommonCategory; 
                }
                else {
                    do {
                        const randIdx = Math.floor(Math.random()*this.categoryCount) + 1;  
                        gameCategory = this.gameCategories[randIdx];  // random game category                  
                    } while (this.noCommonRoundCat.includes(gameCategory.id));                                                
                }
                common = true;
            }

            const wsReq = {
                type: 'GET_QUESTION',
                catId: gameCategory.id,
                common: common,
                card: card,
                fromDate: gameCategory.fromDate,
                versionDate: gameCategory.versionDate,
                questionId: questionId,
                gameId: this.gameId,
            };   
            this.wsSend(wsReq);                      
        },
        travelLotteryResult: function(winner) {
            const points = winner ? 1 : 0;
            this.travelAnswer(points, this.clientId)                     
        },
        travelAnswer: function(points, clientId) {
            const wsReq = {
                type: 'TRAVEL_POINT', 
                gameId: this.gameId,
                travelPoints: points,
                travelPointsClientId: clientId,
                travelCard: this.gameQuestion?.travelCard
            };
            this.wsSend(wsReq);
        },
        sound: function() {
            return this.$refs.sounds;
        },
        initAudio: function() {         
            this.sound().init();                                                  
        },
        getRandomThinkEmoji: function() {                
            const r = Math.floor(Math.random()*this.thinkEmojis.length);                 
            return this.thinkEmojis[r];            
        },          
        formatOption: function(opt) {
            const optLength = [...opt].length;  // ex: 🇦🇿=2
            const realLength = opt.length;  // ex: 🇦🇿=4
            let ret = opt;
            if (optLength == 2 && realLength == 4) {  // emoji flag
                ret = '<span class="text-6xl">' + opt + '</span>';
            } 
            return ret;
        },
        mediaUrl: function(mediaId) {
            return process.env.VUE_APP_API + '/question/media/' + mediaId;
        },   
        magnifyPicture: function(b) {
            this.showLargePicture = b && this.questionImgPortrait;
        },
        questionImageLoaded: function() {
            const img = this.$refs.questionImage;                   
            const widthPercent = Math.round(img.width/window.innerWidth*100); 
            this.questionImgPortrait = widthPercent < 75;
        },
        setGameCategories: function(categories) {            
            if (categories.length < this.categoryCount) {
                return;
            }             
            if (categories.length > this.categoryCount) {
                categories.splice(this.categoryCount, (categories.length-this.categoryCount));
            }
            if (this.isOrganizer) {
                for (let i = 0; i < this.categoryCount; i++) {
                    categories[i]['color'] = this.catColors[i];
                }
                const wsReq = {
                    type: 'SET_CATEGORIES',
                    gameCategories: categories,
                };
                this.wsSend(wsReq);  // send selected categories to all players
            }            
            categories.unshift(this.commonRoundCategory);  // add common round as first category
            this.gameCategories = categories;
        },
        startTravelGame: function() {
            const wsReq = {
                type: 'START_TRAVEL_GAME',
                gameId: this.gameId,
                travelGameDuration: this.travelGameTime,
            };
            this.wsSend(wsReq);  
        },
        updateTravelCommonSliderValue: function(e) {
            this.gameQuestion.travelCommonValue = e.target.value;
        },
        toggleGameCategory: function(cat) {            
            if (!cat.selected) {
                cat.selected = true;
            }
            else {
                cat.selected = !cat.selected;
            }
            if (this.selectedCategories.length > this.categoryCount) {
                cat.selected = false;
            }
        },
        openMenu: function() {
            this.$refs.menu.open();            
        },
        newOrganizer: function() {
            this.wsSend({type: 'NEW_ORGANIZER'}); 
        },
        showAlertMsg: function(heading, text) {
            this.$refs.alert.open(heading, text, 'Lukk');
        },
        showErrorPage: function() {
            this.$router.push({name: 'errorPage'});
        },
        onOrientationChange: function() {
            setTimeout(() => {
                this.calcCategoryHeight();
            }, 250);
        },
        calcCategoryHeight: function() {
            this.catHeight = window.innerHeight/(this.categoryCount+2);  // common round + ?-row
        },
        toggleFullscreen: function() {
            this.isFullscreen = !this.isFullscreen;
            if (this.isFullscreen) {
                document.documentElement.requestFullscreen();
            }
            else {
                document.exitFullscreen();
            }
        },
        getAgeAlias: function(ageGroup, honorHide) {            
            if (ageGroup == 0) {
                //return 'Alle';
            }
            let alias = '';
            this.ageGroups.forEach((g) => {
                if (g.id == ageGroup && (!honorHide || g.hide === false)) {
                    alias = g.alias;
                }
            });
            return alias;
        },
        getMinAgeLabel(minAge) {        
            switch (minAge) {
                case 2: return '13+'; 
                case 3: return '16+'; 
            }
            return '';
        },       
        showCategoryDesc: function() {
            let catInfo = '';
            this.categories.forEach(cat => {        
                const isFree = cat.price === 0 && !cat.isTravel;      
                if (cat.boardCategory || isFree) {
                    const isNewsCat = cat.id === this.newsCategoryID; 
                    const newsIcon = isNewsCat ? '✨' : ''; 
                    const minAgeLabel = this.getMinAgeLabel(cat.minAgeGroup);
                    catInfo += '<div class="mb-2 pb-2 border-b border-gray-300 text-base">';
                    catInfo += '<div class="font-semibold">' + newsIcon + ' ' + cat.name + ' ' +  newsIcon;
                    if (minAgeLabel) {
                        catInfo += '<span class="text-xs rounded font-normal bg-blue text-white ml-1 px-1">' + minAgeLabel + '</span>';
                    }
                    catInfo += '</div>';
                    catInfo += '<div class="text-sm text-gray-600">Versjon ' + catVersion(cat.versionDate) + '</div>'
                    if (cat.resetCount && !isNewsCat) {
                        let times = cat.resetCount > 1 ? 'ganger' : 'gang';
                        catInfo += '<div class="text-sm text-red-500">Stokket om ' + cat.resetCount + ' ' + times + '</div>';
                    }                    
                    catInfo += '<div>' + cat.desc + '</div></div>';
                }
            });
            this.$refs.dialog.open('Dine kategorier', catInfo, 'Lukk');
        },
        buyCategory: function(cat) {
            const ageLimit = this.getMinAgeLabel(cat.minAgeGroup);
            const isNewsCat = cat.id === this.newsCategoryID;
            
            this.$refs.buyDlg.open(cat, this.uuid, ageLimit, isNewsCat, this.connectedCode).then(success => {
                if (success) {
                    window.scrollTo(0, 0);
                    let infoText = 'Kategorien <span class="font-semibold">' + cat.name + '</span> ';
                    infoText += cat.boardCategory ? 'er oppdatert' : 'er nå tilgjengelig';
                    this.showInfoText(infoText);
                    this.getCategories(); // refresh categories
                }
            });
        },
        showInfoText: function(text) {
            this.infoText = text;
        },
        hideInfoText: function() {
            this.infoText = '';
        },
        forceSWupdate: function() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then(registrations => {
                    for (let registration of registrations) {
                        registration.update();
                    }
                });
            }          
        },
        requestWakeLock: function() {                        
            if ('wakeLock' in navigator) {
                try {
                    navigator.wakeLock.request('screen').then(wl => {   
                        this.wakeLock = wl.type + ' ' + new Date(); 
                    });                    
                } catch (err) {
                    console.log(err);
                }     
            } 
        },
        playPointSound: function(newPoints) {
            if (newPoints > this.travelPoints) {
                const count = newPoints - this.travelPoints;
                this.sound().play('pointPlus', count);                
            }
            else if (newPoints < this.travelPoints) {
                this.sound().play('pointMinus');
            }            
        },
        ainouTravelConnected: function(code) {            
            this.connectedCode = code;
            this.getCategories();
        },
        ainouTravelDisconnected: function() {            
            this.connectedCode = null;
            this.getCategories();
        },        
    }
}
</script>

<style scoped>
.pointButton {
    @apply border-2 rounded-full p-4 text-3xl text-center;
}
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; 
    height: 25px; 
    background: #d3d3d3; 
    outline: none;
}
.slider::-webkit-slider-thumb {
    @apply rounded;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; 
    height: 40px; 
    background: #085B91; 

}
.slider::-moz-range-thumb {
    @apply rounded;
    width: 15px; 
    height: 40px; 
    background: #085B91; 
}
</style>